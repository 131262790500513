export default {
  stickers: [
    {
      group: 'Samsung Style',
      item: [
        {id: '101', file: '1-1.svg'},
        {id: '102', file: '1-2.svg'},
        {id: '103', file: '1-3.svg'},
        {id: '104', file: '1-4.svg'},
        {id: '105', file: '1-5.svg'},
        {id: '106', file: '1-6.svg'},
        {id: '107', file: '1-7.svg'},
        {id: '108', file: '1-8.svg'},
        {id: '109', file: '1-9.svg'},
        {id: '110', file: '1-10.svg'},
        {id: '111', file: '1-11.svg'},
      ]
    },
    {
      group: 'SDC21 Hype',
      item: [
        {id: '201', file: '2-1.svg'},
        {id: '202', file: '2-2.svg'},
        {id: '203', file: '2-3.svg'},
        {id: '204', file: '2-4.svg'},
        {id: '205', file: '2-5.svg'},
        {id: '206', file: '2-6.svg'},
        {id: '207', file: '2-7.svg'},
        {id: '208', file: '2-8.svg'},
        {id: '209', file: '2-9.svg'},
        {id: '210', file: '2-10.svg'},
        {id: '211', file: '2-11.svg'},
        {id: '212', file: '2-12.svg'},
      ]
    },
    {
      group: 'Code Zone',
      item: [
        {id: '301', file: '3-1.svg', isAnimation: true},
        {id: '302', file: '3-2.svg', isAnimation: true},
        {id: '303', file: '3-3.svg', isAnimation: true},
        {id: '304', file: '3-4.svg', isAnimation: true},
        {id: '305', file: '3-5.svg', isAnimation: true},
        {id: '306', file: '3-6.svg', isAnimation: true},
        {id: '307', file: '3-7.svg', isAnimation: true},
        {id: '308', file: '3-8.svg', isAnimation: true},
        {id: '309', file: '3-9.svg', isAnimation: true},
        {id: '310', file: '3-10.svg', isAnimation: true, ignore: true},
      ]
    },
  ]
}
